// 
// nav.scss
//


// Bootstrap override - turn nav tab with broder

.nav-tabs {
    border-bottom: 2px solid rgba($gray-600, 0.2);

    .nav-item {
        margin-bottom: -2px;
    }

    li {
        a {
            border-color: transparent !important;
            padding: 0.625rem 1.25rem;
        }
        a.active {
            border-bottom: 2px solid $primary !important;
        }
    }
}

// pills
.nav-tabs,.nav-pills {
    > li {
        > a {
            color: $gray-700;
            font-weight: $font-weight-bold;
        }
    }
}

.nav-pills {
    background: $body-bg;
    border-radius: $border-radius;
    padding: 6px 2px;

    .nav-link {
        background-color: transparent;
        color: $body-color;
    }

    .nav-link.active,
    .show>.nav-link {
        color: $body-color;
        background-color: $card-bg;
        box-shadow: $box-shadow-sm;
    }
}


//Navtab custom
.navtab-bg {
    li {
        >a {
            background-color: $gray-300;
            margin: 0 5px;
        }
    }
}

.tab-content {
    padding: 20px 0 0 0;
}  
.tabs-left, .tabs-right {
    border-bottom: none;
    padding-top: 2px;
  }
  .tabs-left {
    border-right: 1px solid #ddd;
  }
  .tabs-right {
    border-left: 1px solid #ddd;
  }
  .tabs-left>li, .tabs-right>li {
    float: none;
    margin-bottom: 2px;
  }
  .tabs-left>li {
    margin-right: -1px;
  }
  .tabs-right>li {
    margin-left: -1px;
  }
  .tabs-left>li.active>a,
  .tabs-left>li.active>a:hover,
  .tabs-left>li.active>a:focus {
    border-bottom-color: #ddd;
    border-right-color: transparent;
  }
  
  .tabs-right>li.active>a,
  .tabs-right>li.active>a:hover,
  .tabs-right>li.active>a:focus {
    border-bottom: 1px solid #ddd;
    border-left-color: transparent;
  }
  .tabs-left>li>a {
    border-radius: 4px 0 0 4px;
    margin-right: 0;
    display:block;
  }
  .tabs-right>li>a {
    border-radius: 0 4px 4px 0;
    margin-right: 0;
  }


.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  //right: 12px;
  left: 57%;
  z-index: 1;
 // top: 10px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  font-family: "FontAwesome";
  // content: "\f044";
  color: $primary;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
 // height: 192px;
  position: relative;
  border-radius: 100%;
 // border: 6px solid $primary;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}