// 
// card.scss
//

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow-sm;
    border-radius: 0.25rem;
}

.card-drop {
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    color: inherit;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    margin-top: -6px;
    &:hover {
        background-color: $gray-200;
        color: $dark;
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}

.card-nav {
    margin: -0.5rem 0;
}

// Custom card box
.card-box {
    background-color: $card-bg;
    padding: $card-spacer-x;
    box-shadow: $box-shadow-sm;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
}

.header-title {
    font-size: 1.04rem;
    font-weight: 700;
}

.sub-header {
    margin-bottom: $grid-gutter-width;
    color: $gray-600;
}


.card .card-heading {
    padding: 0 20px;
    margin: 0;
}

.card .card-heading.simple {
    font-size: 20px;
    font-weight: 300;
    color: #777;
    border-bottom: 1px solid #e5e5e5;
}

.card .card-heading.image img {
    display: inline-block;
    width: 46px;
    height: 46px;
    margin-right: 15px;
    vertical-align: top;
    border: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.card .card-heading.image .card-heading-header {
    display: inline-block;
    vertical-align: top;
}

.card .card-heading.image .card-heading-header h3 {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: #262626;
}

.card .card-heading.image .card-heading-header span {
    font-size: 12px;
    color: #999999;
}

.card .card-body {
  //  padding: 0 20px;
  //  margin-top: 20px;
}

.card .card-media {
    padding: 0 20px;
    margin: 0 -14px;
}

.card .card-media img {
    max-width: 100%;
    max-height: 100%;
}

.card .card-actions {
    min-height: 30px;
    padding: 0 20px 20px 20px;
    margin: 20px 0 0 0;
}

.card .card-comments {
    padding: 20px;
    margin: 0;
    background-color: #f8f8f8;
}

.card .card-comments .comments-collapse-toggle {
    padding: 0;
    margin: 0 20px 12px 20px;
}

.card .card-comments .comments-collapse-toggle a,
.card .card-comments .comments-collapse-toggle span {
    padding-right: 5px;
    overflow: hidden;
    font-size: 12px;
    color: #999;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-comments .media-heading {
    font-size: 13px;
    font-weight: bold;
}

.card.people {
    position: relative;
    display: inline-block;
    width: 170px;
    height: 300px;
    padding-top: 0;
    margin-left: 20px;
    overflow: hidden;
    vertical-align: top;
}

.card.people:first-child {
    margin-left: 0;
}

.card.people .card-top {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 170px;
    height: 150px;
    background-color: #ffffff;
}

.card.people .card-top.green {
    background-color: #53a93f;
}

.card.people .card-top.blue {
    background-color: #427fed;
}

.card.people .card-info {
    position: absolute;
    top: 150px;
    display: inline-block;
    width: 100%;
    height: 101px;
    overflow: hidden;
    background: #ffffff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
 
}

.card.people .card-info .title {
    display: block;
    margin: 8px 14px 0 14px;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #404040;
}

.card.people .card-info .desc {
    display: block;
    margin: 8px 14px 0 14px;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    color: #737373;
    text-overflow: ellipsis;
}

.card.people .card-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    line-height: 29px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.card.hovercard {
    position: relative;
    padding-top: 0;
    overflow: hidden;
    text-align: center;
    background-color: #f3f4f7;
}

// .card.hovercard .cardheader {
    
//     background-size: cover;
//     height: 80px;
//     width: 90%;
//     margin: auto;
//     //margin-left: 5%;
    
// }

.card.hovercard .avatar {
    position: relative;
    top: -50px;
    margin-bottom: -50px;
}

.card.hovercard .avatar img {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    margin-left: 60%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 10%;
    border: 5px solid rgba(255,255,255,0.5);
    margin-top: 20%;
}

.card.hovercard .info {
    padding: 4px 8px 10px;
    background: #F4F5F4;
   background: #FFFFFF;
   // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    height: 230px;
   // transform: rotate(-90deg);
}
.card.hovercard .info .info-content {
    margin-left: 40%;
    text-align: left;
   // transform: rotate(-90deg);
}
.card.hovercard .info .title {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 1;
    color: #262626;
    //vertical-align: middle;
}

.card.hovercard .info .desc {
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    color: #737373;
    text-overflow: ellipsis;
}

.card.hovercard .bottom {
    padding: 0 20px;
    margin-bottom: 17px;
    background: #F4F5F4;
}

.avatar {
    height: 3rem !important;
    width: 5rem !important;
}
@media screen and (max-width: 600px) {
    .card.hovercard .info .info-content {
        margin-left: 49%;
        text-align: left;
       // transform: rotate(-90deg);
    }
  }