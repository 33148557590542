//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100;
    border-color: $gray-100;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: $gray-300;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: $gray-100;
        padding: 10px;
        border: 1px solid $gray-300;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Input
input {
    &:focus {
        outline: none;
    }
}
.input-form-shift{
    width: 6%; 
    height: calc(1.5em + 1rem + 2px); 
    font-size: 0.875rem; 
    font-weight: 400; 
    border: 1px solid #e2e7f1; 
    border-radius: 0.3rem;
    background-clip: padding-box;
}

// fieldset {
//     margin: 20px auto;
//     /*border-radius: 5px;*/
//     border: solid 1px #57c2e1;
//     //color: #57c2e1;
//     padding: 30px 20px;
//   }
//   legend {
//    // background: #00aaaa;
//     color: #FFF;
//     padding: 5px 10px;
//     border-radius: 0px 5px;
//     border: 1px solid #57c2e1;
    
//   // -moz-box-shadow: 1px 1px 2px #888;
//   //  -webkit-box-shadow: 1px 1px 2px #888;
//        //     box-shadow: 1px 1px 2px #888;
//     -moz-text-shadow: 1px 1px 2px #888;
//     -webkit-text-shadow: 1px 1px 2px #888;
//             text-shadow: 1px 1px 2px #888;
//   }
//   fieldset:hover legend{
//     -moz-box-shadow: 2px 2px 3px #888;
//  -webkit-box-shadow: 2px 2px 3px #888;
//          box-shadow: 2px 2px 3px #888;
 
//    -moz-text-shadow: 1px 1px 3px #555;
//  -webkit-text-shadow: 1px 1px 3px #555;
//          text-shadow: 1px 1px 3px #555;
// }


.search-location-input {
    display: flex;
    width: 484px;
    border-radius: 24px;
    border: 1px solid #d8d8d8;
  }
  
  .search-location-input:focus-within {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: rgba(223,225,229,0);
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  }
  
  .search-location-input:hover {
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
    border-color: rgba(223,225,229,0);
  }
  
  .search-location-input input {
    height: 32px;
    width: 100%;
    padding: 8px 12px;
    border: none;
    font-size: 16px;
    border-radius: 24px;
  }
  
  .search-location-input input:focus {
    outline: none;
  }