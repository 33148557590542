/*
Template Name: Minton - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/
body.dark{
    @import 'bootstrap-dark';
    @import 'icons';
    @import 'app-dark';
}